import React from "react";
import styles from './ChartLabels.module.css';
import { calculatePercentages } from '../../utils/utils';

const ChartLabels = (data) => {
  const percentages = calculatePercentages(Object.values(data).map(item => item.value));

  return (
    <div className={styles.labels}>
      {Object.values(data).map((label, labelIndex) => (
        <div className={styles.label} key={labelIndex}>
          <div className={styles.labelTop}>
            <div className={styles.labelValue}>{label.value}%</div>
            <div className={styles.labelPercent}>({percentages[labelIndex]}%)</div>
          </div>
          <div className={styles.labelBottom}>
            <div className={styles.labelColor} style={{ backgroundColor: label.color }}/>
            <div className={styles.labelName}>{label.label}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ChartLabels;
