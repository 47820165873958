import React from "react";
import styles from './Box.module.css';
import ChartPie from "../ChartPie/ChartPie";
import ChartBar from "../ChartBar/ChartBar";
import ChartLabels from "../ChartLabels/ChartLabels";
import RatiosCompare from "../RatiosCompare/RatiosCompare";
import GridTexts from "../GridTexts/GridTexts";
import ChartRadar from "../ChartRadar/ChartRadar";
import ChartLine from "../ChartLine/ChartLine";


const Box = (boxData) => {
  if (!boxData) {
    console.error('boxData is undefined');
    return null;
  }

  const contentType = {
    'text': <div className={styles.boxText}>{boxData.content}</div>,
    'ChartPie': <div className={styles.boxContent}><ChartPie {...boxData.content} /><ChartLabels {...boxData.content} /></div>,
    'ChartRadar': <div className={styles.boxContent}><ChartRadar {...boxData.content} /></div>,
    'ChartLine': <div className={styles.boxContent}><ChartLine {...boxData.content} /></div>,
    'ChartBar': <div className={styles.boxContent}><ChartBar {...boxData.content} /><ChartLabels {...boxData.content} /></div>,
    'RatiosCompare': <div className={styles.boxContent}><RatiosCompare {...boxData.content} /></div>,
    'GridTexts': <div className={styles.boxContent}><GridTexts {...boxData.content} /></div>,
  };

  return (
    <div className={styles.box}>
      {boxData.title && <h3 className={styles.boxTitle}>{boxData.title}</h3>}
      {boxData.caption && <div className={styles.boxCaption}>{boxData.caption}</div>}
      {boxData.content && contentType[boxData.type]}
    </div>
  );
};
export default Box;
