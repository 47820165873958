import React, { useState } from "react";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";

const ChartLine = (data) => {
  const [seriesData, xAxisLabels] = Object.values(data);
  const [series, setSeries] = useState(
    seriesData.map((item, index) => ({
      name: item.label,
      data: item.value,
      color: item.color,
    }))
  );

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        legendClick: (_, i) => {
          setSeries(
            series.map((item, index) => ({
              name: item.name,
              data: item.data,
              color:
                i === index
                  ? item.color?.includes("6b")
                    ? item.color.replaceAll("6b", "")
                    : item.color + "6b"
                  : item.color,
            }))
          );
        },
      },
    },

    xaxis: {
      categories: xAxisLabels,
    },
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: 3,
    },
  };

  return <Chart options={options} series={series} type="line" height={280} />;
};

export default ChartLine;
