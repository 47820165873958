import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ClickAwayListener,
  Popper,
  MenuItem,
  MenuList,
  Button,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import Popup from "../Popup/Popup";
import styles from "./message-list.module.css";
import { APP_VIEWS } from "../../utils/constants";
import clsx from "clsx";

const MessageList = ({
  messages,
  isOpen,
  setPopupClose,
  appView,
  senderFilter,
  chatNameFilter,
  emotionFilter,
  typeFilter,
  setSenderFilter,
  setChatNameFilter,
  setEmotionFilter,
  setTypeFilter,
}) => {
  const [anchorElSender, setAnchorElSender] = useState(null);
  const [anchorElChatName, setAnchorElChatName] = useState(null);
  const [anchorElEmotion, setAnchorElEmotion] = useState(null);
  const [anchorElType, setAnchorElType] = useState(null);
  const [uniqueSenders, setUniqueSenders] = useState([]);
  const [uniqueChatNames, setUniqueChatNames] = useState([]);
  const [uniqueEmotions, setUniqueEmotions] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);

  useEffect(() => {
    const senders = [...new Set(messages.map((msg) => msg.senderName))].sort(
      (a, b) => a.localeCompare(b)
    );
    const chatNames = [...new Set(messages.map((msg) => msg.chat))].sort(
      (a, b) => a.localeCompare(b)
    );
    const emotions = [...new Set(messages.map((msg) => msg.emotionMessage))];
    const types = [...new Set(messages.map((msg) => msg.typeMessage))];
    setUniqueSenders(senders);
    setUniqueChatNames(chatNames);
    setUniqueEmotions(emotions);
    setUniqueTypes(types);
  }, [messages]);

  const handleSenderClick = (event) => {
    setAnchorElSender(anchorElSender ? null : event.currentTarget);
    handleCloseOtherPopups("sender");
  };

  const handleChatNameClick = (event) => {
    setAnchorElChatName(anchorElChatName ? null : event.currentTarget);
    handleCloseOtherPopups("chatName");
  };

  const handleEmotionClick = (event) => {
    setAnchorElEmotion(anchorElEmotion ? null : event.currentTarget);
    handleCloseOtherPopups("emotion");
  };

  const handleTypeClick = (event) => {
    setAnchorElType(anchorElType ? null : event.currentTarget);
    handleCloseOtherPopups("type");
  };

  const handleClose = () => {
    setAnchorElSender(null);
    setAnchorElChatName(null);
    setAnchorElEmotion(null);
    setAnchorElType(null);
  };

  const handleCloseOtherPopups = (current) => {
    if (current !== "sender") setAnchorElSender(null);
    if (current !== "chatName") setAnchorElChatName(null);
    if (current !== "emotion") setAnchorElEmotion(null);
    if (current !== "type") setAnchorElType(null);
  };

  const clearFilters = () => {
    setSenderFilter("");
    setChatNameFilter("");
    setEmotionFilter("");
    setTypeFilter("");
    handleClose();
  };

  const applySenderFilter = (value) => {
    setSenderFilter(value !== senderFilter ? value : "");
    handleClose();
  };

  const applyChatNameFilter = (value) => {
    setChatNameFilter(value !== chatNameFilter ? value : "");
    handleClose();
  };

  const applyEmotionFilter = (value) => {
    setEmotionFilter(value !== emotionFilter ? value : "");
    handleClose();
  };

  const applyTypeFilter = (value) => {
    setTypeFilter(value !== typeFilter ? value : "");
    handleClose();
  };

  const filteredSortedMessages = useMemo(() => {
    return [...messages]
      .filter((message) => {
        const matchesSender = senderFilter
          ? message.senderName === senderFilter
          : true;
        const matchesChatName = chatNameFilter
          ? message.chat === chatNameFilter
          : true;
        const matchesEmotion = emotionFilter
          ? message.emotionMessage === emotionFilter
          : true;
        const matchesType = typeFilter
          ? message.typeMessage === typeFilter
          : true;
        return (
          matchesSender && matchesChatName && matchesEmotion && matchesType
        );
      })
      .sort((a, b) => new Date(b.time) - new Date(a.time));
  }, [messages, senderFilter, chatNameFilter, emotionFilter, typeFilter]);

  useEffect(() => {
    if (!isOpen) clearFilters();
  }, [isOpen]);

  const renderFilterIcon = (filter) => {
    return filter ? (
      <FilterAltIcon className={styles.titleFilterIcon} />
    ) : (
      <FilterListIcon className={styles.titleFilterIcon} />
    );
  };
  return (
    <div>
      <TableContainer
        component={Paper}
        style={{ maxHeight: "65vh", overflow: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={clsx(styles.cell, styles.headCell)}>
                Время
              </TableCell>
              {appView === APP_VIEWS.CHATS && (
                <TableCell
                  onClick={handleSenderClick}
                  style={{ cursor: "pointer", minWidth: 85 }}
                  className={clsx(styles.cell, styles.headCell)}
                >
                  Автор {renderFilterIcon(senderFilter)}
                </TableCell>
              )}
              {appView === APP_VIEWS.EMPLOYEES && (
                <TableCell
                  className={clsx(styles.cell, styles.headCell)}
                  onClick={handleChatNameClick}
                  style={{ cursor: "pointer" }}
                >
                  Чат {renderFilterIcon(senderFilter)}
                </TableCell>
              )}
              <TableCell
                className={clsx(styles.cell, styles.headCell)}
                style={{ width: "60%", maxWidth: "600px" }}
              >
                Сообщение{" "}
              </TableCell>
              <TableCell
                className={clsx(styles.cell, styles.headCell)}
                onClick={handleEmotionClick}
                style={{ cursor: "pointer" }}
              >
                Эмоция {renderFilterIcon(emotionFilter)}
              </TableCell>
              <TableCell
                className={clsx(styles.cell, styles.headCell)}
                onClick={handleTypeClick}
                style={{ cursor: "pointer" }}
              >
                Тип {renderFilterIcon(typeFilter)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSortedMessages.map((message, index) => (
              <TableRow key={message.id + index}>
                <TableCell className={styles.cell}>
                  {new Date(message.time).toLocaleString()}
                </TableCell>
                {appView === APP_VIEWS.CHATS && (
                  <TableCell className={styles.cell}>
                    {message.senderName}
                  </TableCell>
                )}
                {appView === APP_VIEWS.EMPLOYEES && (
                  <TableCell className={styles.cell}>{message.chat}</TableCell>
                )}
                <TableCell className={styles.cell}>{message.body}</TableCell>
                <TableCell className={styles.cell}>
                  {message.emotionMessage || "-"}
                </TableCell>
                <TableCell className={styles.cell}>
                  {message.typeMessage || "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Popper
        open={Boolean(anchorElSender)}
        anchorEl={anchorElSender}
        placement="bottom-start"
      >
        <div className={styles.popperList}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              {uniqueSenders.map((sender, index) => (
                <MenuItem
                  key={index}
                  onClick={() => applySenderFilter(sender)}
                  style={senderFilter === sender ? { fontWeight: "bold" } : {}}
                >
                  {sender}
                </MenuItem>
              ))}
              {senderFilter && (
                <MenuItem
                  style={{ fontStyle: "italic" }}
                  onClick={clearFilters}
                >
                  Сбросить фильтр
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </div>
      </Popper>
      <Popper
        open={Boolean(anchorElChatName)}
        anchorEl={anchorElChatName}
        placement="bottom-start"
      >
        <div className={styles.popperList}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              {uniqueChatNames.map((chatName, index) => (
                <MenuItem
                  key={index}
                  onClick={() => applyChatNameFilter(chatName)}
                  style={
                    chatNameFilter === chatName ? { fontWeight: "bold" } : {}
                  }
                >
                  {chatName}
                </MenuItem>
              ))}
              {chatNameFilter && (
                <MenuItem
                  style={{ fontStyle: "italic" }}
                  onClick={clearFilters}
                >
                  Сбросить фильтр
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </div>
      </Popper>
      <Popper
        open={Boolean(anchorElEmotion)}
        anchorEl={anchorElEmotion}
        placement="bottom-start"
      >
        <div className={styles.popperList}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              {uniqueEmotions.map((emotion, index) => (
                <MenuItem
                  key={index}
                  onClick={() => applyEmotionFilter(emotion)}
                  style={
                    emotionFilter === emotion ? { fontWeight: "bold" } : {}
                  }
                >
                  {emotion}
                </MenuItem>
              ))}
              {emotionFilter && (
                <MenuItem
                  style={{ fontStyle: "italic" }}
                  onClick={clearFilters}
                >
                  Сбросить фильтр
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </div>
      </Popper>

      <Popper
        open={Boolean(anchorElType)}
        anchorEl={anchorElType}
        placement="bottom-start"
      >
        <div className={styles.popperList}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              {uniqueTypes.map((type, index) => (
                <MenuItem
                  key={index}
                  onClick={() => applyTypeFilter(type)}
                  style={typeFilter === type ? { fontWeight: "bold" } : {}}
                >
                  {type}
                </MenuItem>
              ))}
              {typeFilter && (
                <MenuItem
                  style={{ fontStyle: "italic" }}
                  onClick={clearFilters}
                >
                  Сбросить фильтр
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </div>
      </Popper>

      <Button
        variant="outlined"
        startIcon={<RefreshIcon />}
        onClick={clearFilters}
        style={{ marginTop: "10px" }}
        disabled={
          !senderFilter && !chatNameFilter && !emotionFilter && !typeFilter
        }
      >
        Сбросить фильтр
      </Button>
    </div>
  );
};

export default MessageList;
