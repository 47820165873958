import React from "react";
import Box from "../../components/Box/Box";
import Button from "../Button/Button";
import styles from "./Report.module.css";
import { getReportChatData, getReportEmployeeData } from "./report-utils";
import { usePDF } from "react-to-pdf";
import { current } from "@reduxjs/toolkit";

const Report = (currentItem) => {
  // console.log(getReportChatData(report));
  const { toPDF, targetRef } = usePDF({ filename: `${currentItem.name}.pdf` });
  if (!currentItem || Object.keys(currentItem).length === 0) {
    return null;
  }
  const report =
    currentItem.typeItem === "employee"
      ? getReportEmployeeData(currentItem, toPDF)
      : getReportChatData(currentItem, toPDF);

  return (
    <div className={styles.report} ref={targetRef}>
      <div className={styles.reportRows}>
        {Object.values(report.data).map((row, rowIndex) => (
          <div
            className={`${styles.reportRow} ${
              styles[`columns-${row.columnsCount}`]
            }`}
            key={rowIndex}
          >
            {row.items.map((col, colIndex) => (
              <div className={styles.reportCol} key={colIndex}>
                {col.map((boxData, boxIndex) => (
                  <Box {...boxData} key={boxIndex} />
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.reportFooter}>
        {report.action ? (
          <div className={styles.reportAction}>
            <Button {...report.action} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Report;
