import React from "react";
import Chart from "react-apexcharts";


const ChartPie = (data) => {
  const series = Object.values(data).map(item => item.value);

  const options = {
    colors: Object.values(data).map(item => item.color),
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 320
          },
        }
      },
      {
        breakpoint: 375,
        options: {
          chart: {
            width: 264
          },
        }
      },
    ]
  };

  return (
    <Chart
      options={options}
      series={series}
      type='radar'
    />
  );
};
export default ChartPie;
