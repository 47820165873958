import React from "react";
import styles from "./GridTexts.module.css";
import clsx from "clsx";

const GridTexts = (data) => {
  return (
    <div className={styles.grid}>
      {Object.values(data).map((item, itemIndex) => (
        <div className={clsx(styles.gridItem, item.className)} key={itemIndex}>
          <div className={styles.gridItemSticker}>
            <div
              className={styles.gridItemColor}
              style={{ backgroundColor: item.color }}
            />
            <div className={styles.gridItemTitle}>{item.title}</div>
          </div>
          <div className={styles.gridItemText}>{item.text}</div>
          {item.children}
        </div>
      ))}
    </div>
  );
};
export default GridTexts;
