export const getReportChatData = (report, toPDF) => {
  const reportMessagesKeys = Object.keys(report.reportMessages);
  const reportMessagesValues = Object.values(report.reportMessages);
  const totalMessagesOnWeeks = reportMessagesValues.map(
    (item) => item.positive + item.negative + item.neutral
  );

  return {
    id: 0,
    action: {
      text: "Скачать в PDF",
      type: "secondary",
      selector: "button",
      action: () => toPDF(),
    },
    data: [
      {
        columnsCount: 2,
        items: [
          [
            {
              title: "Состояние чата на сегодня",
              type: "ChartRadar",
              content: [
                {
                  label: `Позитивные (${report.positive} шт.)`,
                  value: Math.round(
                    (report.positive / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Негативные (${report.negative} шт.)`,
                  value: Math.round(
                    (report.negative / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Нейтральные (${report.neutral} шт.)`,
                  value: Math.round(
                    (report.neutral / report.totalMessages) * 100
                  ),
                },
              ],
            },
          ],
          [
            {
              title: "Состояние чата в динамике, %",
              type: "ChartLine",
              content: [
                [
                  {
                    label: "Позитивные",
                    color: "#90BE6D",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.positive / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                  },
                  {
                    label: "Нейтральные",
                    color: "#f8961e",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.neutral / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                  },
                  {
                    label: "Негативные",
                    color: "#F94144",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.negative / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                  },
                ],
                reportMessagesKeys,
              ],
            },
          ],
          [
            {
              type: "ChartRadar",
              content: [
                {
                  label: `Агрессивные (${report.aggressive} шт.)`,
                  value: Math.round(
                    (report.aggressive / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Пассивно-агрессивные (${report.passiveAggressive} шт.)`,
                  value: Math.round(
                    (report.passiveAggressive / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Аналитические (${report.analytical} шт.)`,
                  value: Math.round(
                    (report.analytical / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Поддерживающие (${report.supportive} шт.)`,
                  value: Math.round(
                    (report.supportive / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Юморные (${report.humorous} шт.)`,
                  value: Math.round(
                    (report.humorous / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Императивные (${report.imperative} шт.)`,
                  value: Math.round(
                    (report.imperative / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Творческие (${report.creative} шт.)`,
                  value: Math.round(
                    (report.creative / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Рефлексивные (${report.reflexive} шт.)`,
                  value: Math.round(
                    (report.reflexive / report.totalMessages) * 100
                  ),
                },
              ],
            },
          ],
          [
            {
              type: "ChartLine",
              content: [
                [
                  {
                    label: "Агр",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.aggressive / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#F44336", // Красный
                  },
                  {
                    label: "Пасс-агр",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.passiveAggressive /
                            totalMessagesOnWeeks[index]) *
                            100
                        ) || 0
                    ),

                    color: "#FF9800", // Оранжевый
                  },
                  {
                    label: "Аналит",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.analytical / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),

                    color: "#2196F3", // Синий
                  },
                  {
                    label: "Подд",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.supportive / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#4CAF50", // Зеленый
                  },
                  {
                    label: "Юмор",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.humorous / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#FFEB3B", // Желтый
                  },
                  {
                    label: "Имп",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.imperative / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#9C27B0", // Фиолетовый
                  },
                  {
                    label: "Твор",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.creative / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#3F51B5", // Темно-синий
                  },
                  {
                    label: "Рефл",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.reflexive / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#00BCD4", // Бирюзовый
                  },
                ],
                reportMessagesKeys,
              ],
            },
          ],
        ],
      },
      {
        columnsCount: 1,
        items: [
          [
            {
              title: "Самые обсуждаемые проблемы команды с конкретикой",
              type: "GridTexts",
              content:
                report.report.topics.length > 0
                  ? report.report.topics.map((item) => ({
                      title: item.short,
                      text: item.detailed,
                      color: "#F94144",
                    }))
                  : [{ title: "Нет данных", color: "#F94144" }],
            },
          ],
        ],
      },
      {
        columnsCount: 1,
        items: [
          [
            {
              title: "Ключевые активности команды за неделю",
              type: "GridTexts",
              content:
                report.report.actions.length > 0
                  ? report.report.actions
                      .filter((item) => item.short)
                      .map((item) => ({
                        title: item.short,
                        text: item.detailed,
                        color: "#f8961e",
                      }))
                  : [{ title: "Нет данных", color: "#f8961e" }],
            },
          ],
        ],
      },
      {
        columnsCount: 1,
        items: [
          [
            {
              title: "Рекомендуемые действия",
              type: "GridTexts",
              content:
                report.report.recommendations.length > 0
                  ? report.report.recommendations.map((item) => ({
                      title: item.short,
                      text: item.detailed,
                      color: "#90BE6D",
                    }))
                  : [{ title: "Нет данных", color: "#F94144" }],
            },
          ],
        ],
      },
    ],
  };
};
export const getReportEmployeeData = (report, toPDF) => {
  const reportMessagesKeys = Object.keys(report.reportMessages);
  const reportMessagesValues = Object.values(report.reportMessages);
  const totalMessagesOnWeeks = reportMessagesValues.map(
    (item) => item.positive + item.negative + item.neutral
  );
  return {
    id: 0,
    action: {
      text: "Скачать в PDF",
      type: "secondary",
      selector: "button",
      action: () => toPDF(),
    },
    data: [
      {
        columnsCount: 2,
        items: [
          [
            {
              title: "Состояние сотрудника на сегодня",
              type: "ChartRadar",
              content: [
                {
                  label: `Позитивные (${report.positive} шт.)`,
                  value: Math.round(
                    (report.positive / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Негативные (${report.negative} шт.)`,
                  value: Math.round(
                    (report.negative / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Нейтральные (${report.neutral} шт.)`,
                  value: Math.round(
                    (report.neutral / report.totalMessages) * 100
                  ),
                },
              ],
            },
          ],
          [
            {
              title: "Состояние сотрудника в динамике, %",
              type: "ChartLine",
              content: [
                [
                  {
                    label: "Позитивные",
                    color: "#90BE6D",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.positive / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                  },
                  {
                    label: "Нейтральные",
                    color: "#f8961e",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.neutral / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                  },
                  {
                    label: "Негативные",
                    color: "#F94144",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.negative / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                  },
                ],
                reportMessagesKeys,
              ],
            },
          ],
          [
            {
              type: "ChartRadar",
              content: [
                {
                  label: `Агрессивные (${report.aggressive} шт.)`,
                  value: Math.round(
                    (report.aggressive / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Пассивно-агрессивные (${report.passiveAggressive} шт.)`,
                  value: Math.round(
                    (report.passiveAggressive / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Аналитические (${report.analytical} шт.)`,
                  value: Math.round(
                    (report.analytical / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Поддерживающие (${report.supportive} шт.)`,
                  value: Math.round(
                    (report.supportive / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Юморные (${report.humorous} шт.)`,
                  value: Math.round(
                    (report.humorous / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Императивные (${report.imperative} шт.)`,
                  value: Math.round(
                    (report.imperative / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Творческие (${report.creative} шт.)`,
                  value: Math.round(
                    (report.creative / report.totalMessages) * 100
                  ),
                },
                {
                  label: `Рефлексивные (${report.reflexive} шт.)`,
                  value: Math.round(
                    (report.reflexive / report.totalMessages) * 100
                  ),
                },
              ],
            },
          ],
          [
            {
              type: "ChartLine",
              content: [
                [
                  {
                    label: "Агр",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.aggressive / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#F44336", // Красный
                  },
                  {
                    label: "Пасс-агр",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.passiveAggressive /
                            totalMessagesOnWeeks[index]) *
                            100
                        ) || 0
                    ),

                    color: "#FF9800", // Оранжевый
                  },
                  {
                    label: "Аналит",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.analytical / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),

                    color: "#2196F3", // Синий
                  },
                  {
                    label: "Подд",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.supportive / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#4CAF50", // Зеленый
                  },
                  {
                    label: "Юмор",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.humorous / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#FFEB3B", // Желтый
                  },
                  {
                    label: "Имп",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.imperative / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#9C27B0", // Фиолетовый
                  },
                  {
                    label: "Твор",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.creative / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#3F51B5", // Темно-синий
                  },
                  {
                    label: "Рефл",
                    value: reportMessagesValues.map(
                      (item, index) =>
                        Math.round(
                          (item.reflexive / totalMessagesOnWeeks[index]) * 100
                        ) || 0
                    ),
                    color: "#00BCD4", // Бирюзовый
                  },
                ],
                reportMessagesKeys,
              ],
            },
          ],
        ],
      },
      {
        columnsCount: 1,
        items: [
          [
            {
              title: "Ключевые активности сотрудника (за неделю)",
              type: "GridTexts",
              content: (() => {
                if (report.report === undefined) {
                  return [{ title: "Загрузка...", color: "#f8961e" }];
                }
                if (report.report === null || !report.report.actions) {
                  return [{ title: "Ошибка загрузки данных", color: "red" }];
                }
                return report.report.actions.map((item) => ({
                  title: item.short,
                  text: item.detailed,
                  color: "#f8961e",
                }));
              })(),
            },
            {
              title: "Самые обсуждаемые сотрудником проблемы",
              type: "GridTexts",
              content: (() => {
                if (report.report === undefined) {
                  return [{ title: "Загрузка...", color: "#F94144" }];
                }
                if (report.report === null || !report.report.topics) {
                  return [{ title: "Ошибка загрузки данных", color: "red" }];
                }
                return report.report.topics.map((item) => ({
                  title: item.short,
                  text: item.detailed,
                  color: "#F94144",
                }));
              })(),
            },
            {
              title: "Рекомендуемые действия",
              type: "GridTexts",
              content: (() => {
                if (report.report === undefined) {
                  return [{ title: "Загрузка...", color: "#90BE6D" }];
                }
                if (report.report === null || !report.report.recommendations) {
                  return [{ title: "Ошибка загрузки данных", color: "red" }];
                }
                return report.report.recommendations.map((item) => ({
                  title: item.short,
                  text: item.detailed,
                  color: "#90BE6D",
                }));
              })(),
            },
          ],
        ],
      },
    ],
  };
};
