import React, { useEffect, useState, useMemo, useCallback } from "react";
import styles from "./Dashboard.module.css";
import Layout from "../../components/Layout/Layout";
import TableData from "../../components/TableData/TableData";
import { tablesColumns } from "../../tablesColumns";
import StatusProfile from "../../components/StatusProfile/StatusProfile";
import { APP_VIEWS } from "../../utils/constants";
import AddChat from "../../components/AddChat/AddChat";
import InfoModal from "../../components/InfoModal/InfoModal";

const EMOTIONAL_METRICS = ["positive", "neutral", "negative"];
const STYLE_METRICS = [
  "aggressive",
  "passiveAggressive",
  "analytical",
  "supportive",
  "humorous",
  "imperative",
  "creative",
  "reflexive",
];

const calculateMetrics = (data, metricsList) => {
  return metricsList.reduce((acc, metric, index) => {
    const item = data.find((item) => item.id === index + 1) || {
      value: 0,
      change: 0,
    };
    acc[metric] = { value: item.value || 0, change: item.change || 0 };
    return acc;
  }, {});
};

const Dashboard = ({
  onLogout,
  currentUser,
  profileWa,
  profileTg,
  handleConnect,
  handleDisConnect,
  handleSelectChats,
  allChatsTG,
  allChatsWA,
  loadAllChats,
  chats,
  employees,
  getReport,
  currentChat,
  currentEmployee,
  onCloseReport,
  onClickButtonMessages,
  delByChat,
  handleClickInLabelForFilter,
  isLoadingChats,
  appView,
  setAppView,
  setIsOpenAddChat,
  isOpenAddChat,
  handleAddChat,
  handleDeleteChat,
  handleClickItemEmployee,
  handleClickItemChat,
  setViewReport,
  viewReport,
  messages,
  senderFilter,
  chatNameFilter,
  emotionFilter,
  typeFilter,
  setSenderFilter,
  setChatNameFilter,
  setEmotionFilter,
  setTypeFilter,
  loadEmployesFilteredByChats,
  setEmployeesFilteredByChats,
  employeesFilteredByChats,
}) => {
  const [formData, setFormData] = useState({ chat: [], employee: [] });
  const [tableData, setTableData] = useState([]);
  const filteredEmployees = useMemo(
    () =>
      (employeesFilteredByChats ? employeesFilteredByChats : employees).filter(
        (emp) => {
          if (formData.employee.length === 0 && formData.chat.length === 0) {
            return true;
          }

          return (
            (formData.employee.length === 0
              ? true
              : formData.employee.includes(emp.id)) &&
            (formData.chat.length === 0
              ? true
              : emp.includedChats?.some((chatId) =>
                  formData.chat.includes(chatId)
                ))
          );
        }
      ),
    [formData, employeesFilteredByChats, employees]
  );
  const filteredChats = useMemo(
    () =>
      chats?.filter(
        (chat) =>
          formData.chat.length === 0 ||
          formData.chat.includes(chat.id.toString())
      ) || [],
    [formData.chat, chats]
  );

  const handleFormSubmit = useCallback((data) => {
    setFormData(data);
  }, []);

  const handleClickItem = (id) => {
    if (appView === APP_VIEWS.CHATS) {
      handleClickItemChat(id);
    } else if (appView === APP_VIEWS.EMPLOYEES) {
      handleClickItemEmployee(
        id,
        filteredChats.map((chat) => chat.id)
      );
    }
  };

  useEffect(() => {
    if (appView === APP_VIEWS.EMPLOYEES) {
      if (formData.chat.length === 0) {
        setEmployeesFilteredByChats(null);
      } else {
        loadEmployesFilteredByChats(formData.chat);
      }
    }
  }, [appView, formData.chat.length]);
  return (
    <Layout
      onLogout={onLogout}
      currentUser={currentUser}
      appView={appView}
      setAppView={setAppView}
      setIsOpenAddChat={setIsOpenAddChat}
    >
      <div className={styles.board}>
        {appView === APP_VIEWS.CONNECTION ? (
          <>
            <StatusProfile
              handleConnect={handleConnect}
              handleDisConnect={handleDisConnect}
              profileTg={profileTg}
              profileWa={profileWa}
              handleSelectChats={handleSelectChats}
              isLoadingChats={isLoadingChats}
              setIsOpenAddChat={setIsOpenAddChat}
              isOpenAddChat={isOpenAddChat}
              allChatsTG={allChatsTG}
              allChatsWA={allChatsWA}
              handleAddChat={handleAddChat}
              chats={chats}
              loadAllChats={loadAllChats}
            />
          </>
        ) : (
          <>
            {" "}
            <h2 className={styles.boardTitle}>
              {tablesColumns.find((table) => table.title === appView)?.title ||
                "Таблица не найдена"}
            </h2>
            <div className={styles.boardTable}>
              <TableData
                profileTg={profileTg}
                profileWa={profileWa}
                chats={chats}
                employees={employees}
                onSubmit={handleFormSubmit}
                handleDeleteChat={handleDeleteChat}
                handleClickItem={handleClickItem}
                appView={appView}
                filteredChats={filteredChats}
                filteredEmployees={filteredEmployees}
              />
              <InfoModal
                setViewReport={setViewReport}
                viewReport={viewReport}
                appView={appView}
                onClose={() => {
                  onCloseReport();
                  setViewReport(null);
                }}
                currentChat={currentChat}
                currentEmployee={currentEmployee}
                handleDeleteChat={handleDeleteChat}
                chats={chats}
                onClickButtonMessages={onClickButtonMessages}
                messages={messages}
                senderFilter={senderFilter}
                chatNameFilter={chatNameFilter}
                emotionFilter={emotionFilter}
                typeFilter={typeFilter}
                setSenderFilter={setSenderFilter}
                setChatNameFilter={setChatNameFilter}
                setEmotionFilter={setEmotionFilter}
                setTypeFilter={setTypeFilter}
                filteredChats={filteredChats}
              />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Dashboard;
