import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from "./Layout.module.css";

const Layout = ({
  onLogout,
  children,
  currentUser,
  appView,
  setAppView,
  setIsOpenAddChat,
}) => {
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <Header
          onLogout={onLogout}
          currentUser={currentUser}
          appView={appView}
          setAppView={setAppView}
          setIsOpenAddChat={setIsOpenAddChat}
        />
        <main className={styles.main}>{children}</main>
        <Footer />
      </div>
    </div>
  );
};
export default Layout;
