import React, { useState, useMemo, useCallback, useEffect } from "react";
import styles from "./Filters.module.css";
import Select from "react-select";
import { selectTheming } from "../../utils/CSelect";
import { tablesColumns } from "../../tablesColumns";

const Filters = ({ appView, onSubmit, chats, employees }) => {
  const [selectedChat, setSelectedChat] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const currentTable = useMemo(
    () => tablesColumns.find((table) => table.title === appView),
    [appView]
  );
  const employeeOptions = useMemo(
    () =>
      employees.map((employee) => ({
        value: String(employee.id),
        label: employee.name,
        includedChats: employee.includedChats,
      })),
    [employees]
  );

  const chatOptions = useMemo(
    () =>
      chats.map((chat) => ({
        value: String(chat.id),
        label: chat.name,
      })),
    [chats]
  );

  const filteredEmployeeOptions = useMemo(() => {
    if (
      !currentTable?.filters?.includes("employees") ||
      selectedChat.length === 0
    )
      return employeeOptions;

    const selectedChatIds = new Set(selectedChat.map((chat) => chat.value));
    return employeeOptions.filter((employee) =>
      employee.includedChats?.some((chatId) => selectedChatIds.has(chatId))
    );
  }, [selectedChat, employeeOptions, currentTable?.filters, employees, chats]);

  const filteredChatOptions = useMemo(() => {
    if (
      !currentTable?.filters.includes("chats") ||
      selectedEmployee.length === 0
    )
      return chatOptions;
    const selectedEmployeeIds = new Set(
      selectedEmployee.map((emp) => emp.value)
    );

    const filteredEmployees = employees.filter((emp) =>
      selectedEmployeeIds.has(emp.id)
    );

    return chatOptions.filter((chat) =>
      filteredEmployees.every((emp) => emp.includedChats.includes(chat.value))
    );
  }, [selectedEmployee, chatOptions, currentTable?.filters, employees, chats]);

  const handleChange = useCallback(
    (type, newOptions) => {
      const updatedOptions = {
        chat: type === "chat" ? newOptions : selectedChat,
        employee: type === "employee" ? newOptions : selectedEmployee,
      };

      if (type === "chat") {
        setSelectedChat(newOptions);
      } else {
        setSelectedEmployee(newOptions);
      }

      onSubmit({
        chat: updatedOptions.chat.map((chat) => chat.value),
        employee: updatedOptions.employee.map((emp) => emp.value),
      });
    },
    [onSubmit, selectedChat, selectedEmployee]
  );

  const selectProps = {
    ...selectTheming(false),
    isMulti: true,
    closeMenuOnSelect: false,
    noOptionsMessage: () => "Результатов не найдено",
    isClearable: true,
  };

  useEffect(() => {
    setSelectedChat([]);
    setSelectedEmployee([]);
  }, [appView]);

  return (
    <div className={styles.filters}>
      <div className={styles.filtersFields}>
        {currentTable?.filters.includes("employees") && (
          <div className={styles.filtersField}>
            <Select
              {...selectProps}
              value={selectedEmployee}
              onChange={(options) => handleChange("employee", options)}
              options={filteredEmployeeOptions}
              placeholder="Выберите сотрудников"
            />
          </div>
        )}
        {currentTable?.filters.includes("chats") && (
          <div className={styles.filtersField}>
            <Select
              {...selectProps}
              value={selectedChat}
              onChange={(options) => handleChange("chat", options)}
              options={filteredChatOptions}
              placeholder="Выберите чаты"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
