import React from "react";
import styles from './Footer.module.css';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerCopyright}>Mood Monitor AI 2024</div>
        </footer>
    );
};
export default Footer;
