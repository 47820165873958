import React from "react";
import Chart from "react-apexcharts";

const ChartRadar = (data) => {
  const series = [
    {
      data: Object.values(data).map((item) => item.value),
    },
  ];

  const categories = Object.values(data).map((item) => item.label);
  const numberOfValues = categories.length;

  const marginBottom = numberOfValues > 3 ? "-10px" : "-60px";

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: Object.values(data).map((item) => item.label),
    },
    yaxis: {
      show: true,
      tickAmount: 5,
      min: 0,
      max: Math.max(...Object.values(data).map((item) => item.value)),
    },
    grid: {
      show: false,
      padding: {
        top: 0,
        right: 0,
        bottom: -30,
        left: 0,
      },
    },
    colors: ["#2D9CDB"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    markers: {
      size: 5,
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="radar"
      style={{ marginBottom: marginBottom }}
    />
  );
};

export default ChartRadar;
