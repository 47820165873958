import React, { useState, useContext, useMemo } from "react";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import inputStyles from "../../components/Input/Input.module.css";
import Button from "../../components/Button/Button";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const notyf = useMemo(() => new Notyf(), []);

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(email, password);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <Logo />
        <div className={styles.logoText}>AI Mood Monitor</div>
      </div>
      <div className={styles.form}>
        <div className={styles.formTitle}>Авторизация</div>
        <form onSubmit={handleSubmit} className={styles.formFields}>
          <div className={inputStyles.field}>
            <label className={inputStyles.label}>Почта</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Введите почту"
              className={inputStyles.input}
              required
            />
          </div>
          <div className={inputStyles.field}>
            <label className={inputStyles.label}>Пароль</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Введите пароль"
              className={inputStyles.input}
              required
            />
          </div>
          <Button {...{ text: "Войти", type: "primary", selector: "button" }} />
        </form>
      </div>
    </div>
  );
};

export default Login;
