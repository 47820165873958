import React, { useState } from "react";
import Button from "../Button/Button";
import styles from "./AddChat.module.css";
import Select from "react-select";
import {
  selectTheming,
  DropdownIndicator,
  ClearIndicator,
} from "../../utils/CSelect";
const AddChat = ({
  setIsOpenAddChat,
  allChatsWA,
  allChatsTG,
  isLoadingChats,
  profileWa,
  profileTg,
  handleAddChat,
  chats,
  loadAllChats,
}) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const handleChatChange = (selectedOption) => {
    setSelectedChat(selectedOption);
  };
  const chatsIds = new Set(chats.map((c) => c.chat_id));
  return (
    <>
      <div className={styles.addChat}></div>
      <div className={styles.addChat} style={{ gap: "6px" }}>
        <Select
          {...selectTheming(false)}
          components={{ DropdownIndicator, ClearIndicator }}
          value={selectedChat}
          onChange={handleChatChange}
          // onMenuOpen={handleMenuOpen}
          options={[
            {
              label: "Whatsapp",
              options: [
                ...allChatsWA
                  .filter((c) => c.name && !chatsIds.has(c.id))
                  .sort((a, b) => b.last_timestamp - a.last_timestamp)
                  .map((c, i) => ({
                    value: c.id,
                    label: c.name,
                    profile_id: profileWa.profile_id,
                    key: c.id + c.name + i + "wa",
                  })),
              ],
            },
            {
              label: "Telegram",
              options: [
                ...allChatsTG
                  .filter((c) => c.name && !chatsIds.has(c.id))
                  .sort((a, b) => b.last_timestamp - a.last_timestamp)
                  .map((c, i) => ({
                    value: c.id,
                    label: c.name,
                    profile_id: profileTg.profile_id,
                    key: c.id + c.name + i + "tg",
                  })),
              ],
            },
          ]}
          noOptionsMessage={() => "Результатов не найдено"}
          isClearable
          placeholder={isLoadingChats ? "Загрузка чатов..." : "Выберите чат"}
          className={styles.selectChat}
        />
        <Button
          text="Добавить"
          type={selectedChat ? "primary" : "secondary"}
          disabled={!selectedChat}
          action={() => {
            handleAddChat(selectedChat);
            setIsOpenAddChat(false);
          }}
        />
        <Button
          text="Обновить"
          disabled={isLoadingChats}
          type={"secondary"}
          action={() => {
            loadAllChats();
          }}
        />
        <Button
          text="Отмена"
          type="secondary"
          action={() => setIsOpenAddChat(false)}
        />
      </div>
    </>
  );
};

export default AddChat;
