import React, { useContext, useEffect, useState } from "react";
import styles from "./Header.module.css";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as Logout } from "../../assets/img/logout.svg";
import Button from "../Button/Button";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { APP_VIEWS } from "../../utils/constants";

const Header = ({
  onLogout,
  currentUser,
  appView,
  setAppView,
  setIsOpenAddChat,
}) => {
  const { logout } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logOutAction = {
    text: isMobile ? <Logout /> : "Выйти",
    type: "secondary",
    selector: "button",
    action: onLogout,
    className: "button--icon",
  };

  const handleTabClick = (tabDisplay) => {
    setAppView(tabDisplay);
    if (tabDisplay === APP_VIEWS.ADMIN) {
      navigate("/admin");
    }
    setIsOpenAddChat(false);
  };
  const adminButton = {
    text: "Админка",
    type: "primary",
    selector: "button",
    action: () => navigate("/admin"),
  };
  return (
    <header className={styles.header}>
      <div className={styles.headerLogo}>
        <Logo />
        <div className={styles.headerLogoText}>
          Mood
          <br />
          monitor <span>ai</span>
        </div>
      </div>
      <div className={styles.headerMenu}>
        {Object.entries(APP_VIEWS)
          .filter(([key, view]) =>
            currentUser.is_admin ? true : view !== "Админка"
          )
          .map(([key, view]) => (
            <Button
              text={view}
              type={appView === view ? "primary" : "secondary"}
              action={() => handleTabClick(view)}
              key={key}
            />
          ))}
      </div>
      <div className={styles.headerUser}>{currentUser.email}</div>
      <div className={styles.headerAction}>
        <Button {...logOutAction} />
      </div>
    </header>
  );
};

export default Header;
