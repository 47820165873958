import React from "react";
import styles from './Button.module.css';

const Button = ({ text, type, selector, link, target, action, disabled, className }) => {
  const classNames = `${styles.button} ${styles[`button--${type}`]} ${className ? styles[className] : ''}`;

  if (selector === 'a') {
    return (
      <a href={link} className={classNames} target={target}>
        {text}
      </a>
    );
  }

  return (
    <button onClick={action} className={classNames} disabled={disabled}>
      {text}
    </button>
  );
};

export default Button;