import React from "react";
import Chart from "react-apexcharts";


const ChartBar = (data) => {
  const series = [{
    data: Object.values(data).map(item => item.value)
  }];

  const options = {
    chart: {
      type: 'bar',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false,
      },

    },
    grid: {
      show: true,
      padding: { left: -11, right: 0, top: -30, bottom: -15 },
    },
    plotOptions: {
      bar: {
        barHeight: 8,
        distributed: true,
        horizontal: true,
      }
    },
    colors: Object.values(data).map(item => item.color),
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: false,
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: '#4F4F4F'
        }
      },
      axisBorder: {
        color: '#BDBDBD',
      },
      axisTicks: {
        color: '#BDBDBD',
      },
    }
  };

  return (
    <Chart
      options={options}
      series={series}
      type='bar'
    />
  );
};
export default ChartBar;
